














































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { QDialog } from 'quasar'

@Component({
  components: {
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconX: () => import('@tada/icons/dist/IconX.vue'),
  },
})
export default class ConfirmDialog extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly title!: string

  @Prop({
    type: String,
    required: true,
  }) readonly description!: string

  @Prop({
    type: String,
    required: true,
  }) readonly okCaption!: string

  @Prop({
    type: String,
    required: true,
  }) readonly cancelCaption!: string

  @Ref() readonly dialog!: QDialog

  public show (): void {
    this.dialog.show()
  }

  private hide (): void {
    this.dialog.hide()
  }

  private onDialogHide () {
    this.$emit('hide')
  }

  private ok () {
    this.$emit('ok')
    this.hide()
  }
}
